// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e8f1f1;
$primaryMain: #00878d;
$primaryDark: #005c61;
$primary200: #66b3b8;
$primary800: #00474d;

// secondary
$secondaryLight: #ffcc99;
$secondaryMain: #e69e4d;
$secondaryDark: #b37700;
$secondary200: #ffd9b3;
$secondary800: #804d00;

// $secondaryLight: #ede7f6;
// $secondaryMain: #673ab7;
// $secondaryDark: #5e35b1;
// $secondary200: #b39ddb;
// $secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #f8fafc;
$grey100: #eef2f6;
$grey200: #e3e8ef;
$grey300: #cdd5df;
$grey400: #aeb9c8;
$grey500: #697586;
$grey600: #4b5565;
$grey700: #364152;
$grey900: #121926;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1C1C1C; // level 3
$darkPaper: #262626; // level 4

// $darkBackground: #1a223f; // level 3
// $darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #2B2B2B;
$darkPrimaryMain: #00878d;
$darkPrimaryDark: #4F9A9F;
$darkPrimary200: #0F4B4F;
$darkPrimary800: #164F53; 

// secondary dark
// $darkSecondaryLight: #7A4D1F;
// $darkSecondaryMain: #e69e4d;
// $darkSecondaryDark: #E6CC99;
// $darkSecondary200: #ffd9b3;
// $darkSecondary800: #804d00;

$darkSecondaryLight: #7A4D1F;
$darkSecondaryMain: #B37700;
$darkSecondaryDark: #D9A34D;
$darkSecondary200: #5E3610;
$darkSecondary800: #6F3F14;


$darkWarningLight: #1F1F1F;
$darkWarningMain:#664B00;
$darkWarningDark: #030303;

// text variants
// $darkTextTitle: #d7dcec;
// $darkTextPrimary: #B2BDCD;
// $darkTextSecondary: #B8BEC7;

$darkTextTitle: #FFFFFF;
$darkTextPrimary: #FFFFFF;
$darkTextSecondary: #818181;

$darkDivider: #2b2b2b;

$darkGrey50: #1F1F1F;
$darkGrey700:  #E1E1E1;
$darkGrey900: #F1F1F1;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey400: $grey400;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;

  darkWarningLight: $darkWarningLight;
  darkWarningMain: $darkWarningMain;
  darkWarningDark:$darkWarningDark;

  darkDivider: $darkDivider;

  darkGrey50: $darkGrey50;
  darkGrey700: $darkGrey700;
  darkGrey900: $darkGrey900;
}
